import { AxiosResponse } from 'axios';
import agent from '../base';

class RaffleApi {
  async postQrData(qrId: string, mirrorId: string, deviceInfo: string, isEnable: boolean) {
    
    try {
      const response: AxiosResponse = await agent.post(`/raffle`, {
        qrData: qrId,
        mirrorId,
        deviceInfo,
        isEnable,
      });

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const raffleApi = new RaffleApi();
export default raffleApi;
