import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
export interface LoginParams {
  email: string;
  password: string
}

export interface QrDataParams {
  qrId: string;
  mirrorId: string;
  deviceInfo: string;
  isEnable: boolean;
}

export interface QrDataResponse {
  isEnable: boolean;
  createdAt: string;
}

export interface AuthUser {
  id: number
  email: string;
  username: string;
  role: string[];
  groupId: number;
  mirrorIds?: number[]
  isArProjectCreationExpired?: boolean
}

export interface AuthState {
  loading: boolean;
  error: boolean;
  isAuthenticated: boolean;
  authUser: AuthUser;
  qrDataRes: QrDataResponse; 
}

export const initialState: AuthState = {
  loading: false,
  error: false,
  authUser: {
    id: 0,
    email: '',
    username: '',
    role: [''],
    groupId: 0,
    mirrorIds: [],
    isArProjectCreationExpired: false
  },
  isAuthenticated: false,
  qrDataRes: {
    isEnable: true,
    createdAt: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState: () => initialState,
    login(state: AuthState, action: PayloadAction<LoginParams>) {
      state.loading = true
      state.error = false
    },
    loginSuccess(state: AuthState, action: PayloadAction<AuthUser>) {
      state.loading = false
      state.authUser = action.payload
      state.isAuthenticated = true
    },
    loginError(state: AuthState) {
      state.loading = false
      state.error = true
    },
    checkUserArProjectCreationExpired(state: AuthState) {
      
    },
    userArProjectCreationExpiredChecked(state: AuthState, action: PayloadAction<boolean>) {
      state.authUser.isArProjectCreationExpired = action.payload
    },
    postQrData(state: AuthState, action: PayloadAction<QrDataParams>) {
      state.loading = true
      state.error = false
    },
    qrDataRes(state: AuthState, action: PayloadAction<QrDataResponse>) {
      state.loading = false
      state.qrDataRes = action.payload
    },
    qrDataResError(state: AuthState) {
      state.loading = false
      state.error = true
    },
  }
})

export const authActions = authSlice.actions
export default authSlice.reducer